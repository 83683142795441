@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
*::-webkit-scrollbar {
  width: 0.3em;
}
*::-webkit-scrollbar-track {
  background-color: transparent;
  /* margin: 0.2em; */
}
*::-webkit-scrollbar-thumb {
  background-color: theme("colors.primary");
  border-radius: 0.5rem;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

/* "Secret" class cause C-man */
.olan {
  visibility: visible;
}
